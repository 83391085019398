import LoadableContentSpinner from 'components/Universal/LoadableContentSpinner';
import HeaderContext from 'providers/HeaderProvider';
import ThemeContext from 'providers/ThemeProvider';
import React, { useContext, useEffect } from 'react';
import Loadable from 'react-loadable';

const CoreTemplate = Loadable({
	loader: () => import('components/Templates/advanced/CoreTemplate'),
	loading: LoadableContentSpinner
});

export default function calculatorProposal({ location }) {
	const { setHeader } = useContext(HeaderContext);
	const { setPrimaryColor } = useContext(ThemeContext);

	useEffect(() => {
		setHeader({ title: 'Wniosek Selekt', isSelect: true });
		setPrimaryColor('#3B81B9');
	}, []);

	return <CoreTemplate location={location} />;
}
